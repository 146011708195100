import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Login from 'pages/login';
import Dashboard from 'pages/dashboard';
import Orders from 'pages/orders';
import Bulk from 'pages/bulk';
import Users from 'pages/users';
import Clients from 'pages/clients';
import Profile from 'pages/profile';
import Recover from 'pages/recover';
import NewPassword from 'pages/recover/password';
import Tracking from 'pages/tracking';

import { getUser } from 'services/auth';
import { Creators } from 'store/ducks/signin';

const Index = (props) => {
  const dispatch = useDispatch();

  useEffect(async () => {
    const userLogged = getUser();
    if(userLogged && userLogged?.hash) {
      dispatch(Creators.setSigninUser(userLogged));
    }
  },[]);

  const NotFound = () => {
    return (<div>Page Not Found</div>);
  };
  
  return (
    <Router history={useNavigate}>
      <Fragment>
        <Routes>
          <Route exact path='/' element={<Login />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/orders' element={<Orders />} />
          <Route path='/bulk' element={<Bulk />} />
          <Route path='/users' element={<Users />} />
          <Route path='/clients' element={<Clients />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/recover' element={<Recover />} />
          <Route path='/password' element={<NewPassword />} />
          <Route path='/tracking' element={<Tracking/>} />
          <Route path='*' element={<NotFound />}/>
          {/* <Route path='contacts/:id' element={ContactDetail} /> */}
        </Routes>
      </Fragment>
    </Router>
  );
}

export default Index;