import Avatar from '@material-ui/core/Avatar';
import Logo from 'assets/logo.png';
import PropTypes from 'prop-types';
import * as React from 'react';


function Logomarca(props) {
  const {small} = props;
  return (
    <Avatar variant="square" src={Logo} 
      style={{width: small?150:'auto', height:small?60:'auto', marginBottom: small?0:20}}
    />
  );
}

Logomarca.propTypes = {
  children: PropTypes.node,
};

export default Logomarca;
