import { combineReducers } from "redux";
import orders from "./orders";
import bulk from "./bulk";
import signin from "./signin";
import upload from "./upload";
import users from "./users";
import validate from "./validate";
import clients from "./clients";
import tracking from "./tracking";

const reducers = combineReducers({
  signin,
  orders,
  bulk,
  users,
  validate,
  upload,
  clients,
  tracking
});

export default reducers