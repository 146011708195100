import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ReceiptPageIcon from '@material-ui/icons/Receipt';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitToApp from '@material-ui/icons/ExitToApp';
import FolderIcon from '@material-ui/icons/Folder';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
// Actions
import { Creators } from 'store/ducks/signin';

export const MainListItems = (props) => {
  const navigate = useNavigate();

  return (
    <div>
      <ListItem button component="a" onClick={()=>navigate('/dashboard')}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
      
      <ListItem button component="a" onClick={()=>navigate('/orders')}>
        <ListItemIcon>
          <ReceiptPageIcon/>
        </ListItemIcon>
        <ListItemText primary="Ordens" />
      </ListItem>

      <ListItem button component="a" onClick={()=>navigate('/bulk')}>
        <ListItemIcon>
          <ReceiptPageIcon/>
        </ListItemIcon>
        <ListItemText primary="Atualizar Status" />
      </ListItem>
      
      <ListItem button component="a" onClick={()=>navigate('/users')}>
        <ListItemIcon>
          <PeopleIcon/>
        </ListItemIcon>
        <ListItemText primary="Usuários" />
      </ListItem>

      <ListItem button component="a" onClick={()=>navigate('/clients')}>
        <ListItemIcon>
          <FolderIcon/>
        </ListItemIcon>
        <ListItemText primary="Clientes" />
      </ListItem>

      <ListItem button component="a" onClick={()=>navigate('/profile')}>
        <ListItemIcon>
          <PersonIcon/>
        </ListItemIcon>
        <ListItemText primary="Perfil" />
      </ListItem>
    </div>
  )
};

export const SecondaryListItems = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div>
      <ListSubheader inset></ListSubheader>
      <ListItem button component="a" onClick={()=> {
        dispatch(Creators.signout());
        navigate('/')
      }}>
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        <ListItemText primary="Desconectar" />
      </ListItem>
    </div>
  );
}