import { Box, Button, Container, CssBaseline, Grid, Link, TextField, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Copyright from 'pages/components/Copyright';
import Logomarca from 'pages/components/logo';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { isValidCellphone, isValidMail } from 'services/helpers';
// Actions
import { Creators } from 'store/ducks/validate';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const Index = (props, context) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { pending, error, data } = useSelector(state => state.validate);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    if(data) {
      const {email, cellphone} = data;
      if(email){
        navigate('password', {email})
      } else if (cellphone) {
        navigate('password', {cellphone})
      }
    }
  },[data]);

  const handleSubmit = () => {
    if(isValidMail(userName)){
      dispatch(Creators.sendEmail(userName));
    } else {
      if(isValidCellphone(userName)) {
        dispatch(Creators.sendCellphone(`+55${userName}`));
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper} style={{justifyContent:'center'}}>
        <Logomarca />
        <Typography component="h1" variant="h5">
          Recuperar Senha
        </Typography>

        <div className={classes.form} noValidate>
          <TextField disabled={pending}
            value={userName}
            onChange={e => setUserName(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="userName"
            label="Email ou Celular"
            name="userName"
            autoFocus
          />
        </div>
        <Button disabled={pending}
          fullWidth
          type="button"
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
        >
          {pending?'conectando...':'CONFIRMAR'}
        </Button>
      </div>
      <Grid container>
        <Grid item xs>
          <Link href="#" variant="body2" onClick={()=>navigate('/')}>
            Fazer Login
          </Link>
        </Grid>
      </Grid>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default Index
