export const ENV = process.env.REACT_APP_ENV;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const MAP_API = process.env.REACT_APP_GOOGLE_GEOCODING_API;
export const STORE_KEY = process.env.REACT_APP_USER_STORE_KEY;

export const TOOKAN_URL = process.env.REACT_APP_TOOKAN_URL;
export const TOOKAN_TOKEN = process.env.REACT_APP_TOOKAN_TOKEN;

export const UFS = [
  {label: "Acre", value: "AC"},
  {label: "Alagoas", value: "AL"},
  {label: "Amapá", value: "AP"},
  {label: "Amazonas", value: "AM"},
  {label: "Bahia", value: "BA"},
  {label: "Ceará", value: "CE"},
  {label: "Distrito Federal", value: "DF"},
  {label: "Espírito Santo", value: "ES"},
  {label: "Goiás", value: "GO"},
  {label: "Maranhão", value: "MA"},
  {label: "Mato Grosso", value: "MT"},
  {label: "Mato Grosso do Sul", value: "MS"},
  {label: "Minas Gerais", value: "MG"},
  {label: "Pará", value: "PA"},
  {label: "Paraíba", value: "PB"},
  {label: "Paraná", value: "PR"},
  {label: "Pernambuco", value: "PE"},
  {label: "Piauí", value: "PI"},
  {label: "Rio de Janeiro", value: "RJ"},
  {label: "Rio Grande do Norte", value: "RN"},
  {label: "Rio Grande do Sul", value: "RS"},
  {label: "Rondônia", value: "RO"},
  {label: "Roraima", value: "RR"},
  {label: "Santa Catarina", value: "SC"},
  {label: "São Paulo", value: "SP"},
  {label: "Sergipe", value: "SE"},
  {label: "Tocantins", value: "TO"}
]

export const VENUX_STATUS = {
  REQUEST_RECEIVED: 'REQUEST_RECEIVED',
  PICKUP_ACCEPTED: 'PICKUP_ACCEPTED',
  PICKUP_STARTED: 'PICKUP_STARTED',
  PICKUP_SUCCESSFUL: 'PICKUP_SUCCESSFUL',
  PICKUP_FAILURE: 'PICKUP_FAILURE',
  PICKUP_IN_PROGRESS: 'PICKUP_IN_PROGRESS',
  PICKUP_CANCELED: 'PICKUP_CANCELED',
  PICKUP_DELETED: 'PICKUP_DELETED',

  DELIVERY_ACCEPTED: 'DELIVERY_ACCEPTED',
  DELIVERY_STARTED: 'DELIVERY_STARTED',
  DELIVERY_SUCCESSFUL: 'DELIVERY_SUCCESSFUL',
  DELIVERY_FAILURE: 'DELIVERY_FAILURE',
  DELIVERY_IN_PROGRESS: 'DELIVERY_IN_PROGRESS',
  DELIVERY_CANCELED: 'DELIVERY_CANCELED',
  DELIVERY_DELETED: 'DELIVERY_DELETED',
}

export const VENUX_STATUS_TRANSLATE = {
  REQUEST_RECEIVED: 'Pedido Recebido',
  PICKUP_ACCEPTED: 'Coleta aceita',
  PICKUP_STARTED: 'Coleta iniciada',
  PICKUP_SUCCESSFUL: 'Sucesso na coleta',
  PICKUP_FAILURE: 'Falha na coleta',
  PICKUP_IN_PROGRESS: 'Coleta em andamento',
  PICKUP_CANCELED: 'Coleta cancelada',
  PICKUP_DELETED: 'Coleta apagada',

  DELIVERY_ACCEPTED: 'Pedido de entrega aceito',
  DELIVERY_STARTED: 'Pedido de entrega iniciado',
  DELIVERY_SUCCESSFUL: 'Sucesso na entrega',
  DELIVERY_FAILURE: 'Falha na entrega',
  DELIVERY_IN_PROGRESS: 'Entrega em andamento',
  DELIVERY_CANCELED: 'Entrega cancelada',
  DELIVERY_DELETED: 'Entrega apagada',
}

export const JOB_TYPES = { 
  PICKUP: 0, 
  DELIVERY: 1 
}

export const JOB_STATUS= {
  ASSIGNED: 0,
  STARTED: 1,
  SUCCESSFUL: 2,
  FAILED: 3,
  INPROGRESS: 4,
  UNASSIGNED: 6,
  ACCEPTED: 7,
  DECLINE: 8,
  CANCEL: 9,
  DELETED: 10
}

export const customersColors = [
  {provider: "linx", color: "#000000"}, // Black
  {provider: "", color: "#1A1A1A"}, // Very dark gray
  {provider: "loccitane", color: "#333333"}, // Dark gray
  {provider: "restoque", color: "#4D4D4D"}, // Medium dark gray
  {provider: "", color: "#4B0082"}, // Medium gray
  {provider: "", color: "#800000"}, // Maroon
  {provider: "reversa-veste", color: "#8B0000"}, // Dark red
  {provider: "", color: "#2F4F4F"}, // Dark slate gray
  {provider: "vivara", color: "#556B2F"}, // Dark olive green
  {provider: "", color: "#483D8B"}, // Dark slate blue
  {provider: "", color: "#2E8B57"}, // Sea green
  {provider: "enjoei", color: "#008080"}, // Teal
  {provider: "dengo", color: "#191970"}, // Midnight blue
  {provider: "", color: "#00008B"}, // Dark blue
  {provider: "", color: "#8B008B"}, // Dark magenta
  {provider: "vivo", color: "#6A5ACD"}, // Slate blue
  {provider: "", color: "#7F00FF"}, // Violet
  {provider: "", color: "#9400D3"}, // Dark violet
  {provider: "another", color: "#696969"}  // Dim gray
];
