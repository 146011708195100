import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  fetchClients: [],
  fetchClientsSuccess: ['clients'],
  fetchClientsFailure: ['error'],
  fetchClient: ['id'],
  fetchClientSuccess: ['client'],
  fetchClientFailure: ['error'],
  fetchClientByDoc: ['doc'],
  fetchClientByDocSuccess: ['clients'],
  fetchClientByDocFailure: ['error'],
  postClient: ['client'],
  postClientSuccess: ['data'],
  postClientFailure: ['error'],
  deleteClient: ['id'],
  deleteClientSuccess: ['data'],
  deleteClientFailure: ['error']
})

/**
 * Handlers
 */
const STATE = {
  pending: false,
  clients: [],
  client: null,
  modified: false, //inserted, updated, deleted
  error: null
}
const INITIAL_STATE = STATE;

// Get All Clients
const getClients = state => {
  return {...state, pending:true, client:null, clients:[], error:null}
}
const getClientsSuccess = (state, { clients }) => {
  return {...state, clients, pending:false, error:null}
};
const getClientsFailure = (state, { error }) => {
  return {...state, pending:false, error }
};

// Get Client by Id
const getClient = state => {
  return {...state, pending:true, error:null}
}
const getClientSuccess = (state, { client }) => {
  return {...state, client, pending:false, error:null}
};
const getClientFailure = (state, { error }) => {
  return {...state, pending:false, error }
};

// Get Client by Document (cpf/cnpj)
const getClientByDoc = state => {
  return {...state, pending:true, error:null}
}
const getClientByDocSuccess = (state, { client }) => {
  return {...state, client, pending:false, error:null}
};
const getClientByDocFailure = (state, { error }) => {
  return {...state, pending:false, error }
};

// Insert Clients
const insertClient = (state, {client}) => {
  return {...state, pending:true, modified:false, error:null}
}
const insertClientSuccess = (state, { data }) => {
  return {...state, pending:false, client:data, modified:true, error:null}
};
const insertClientFailure = (state, { error }) => {
  return {...state, pending: false, error }
};

// Remove Clients
const removeClient = state => {
  return {...state, pending:true, modified:false, error:null}
}
const removeClientSuccess = (state, { data }) => {
  return {...state, pending:false, data, modified:true, error:null}
};
const removeClientFailure = (state, { error }) => {
  return {...state, pending: false, error }
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.FETCH_CLIENTS]: getClients,
  [Types.FETCH_CLIENTS_SUCCESS]: getClientsSuccess,
  [Types.FETCH_CLIENTS_FAILURE]: getClientsFailure,
  [Types.FETCH_CLIENT]: getClient,
  [Types.FETCH_CLIENT_SUCCESS]: getClientSuccess,
  [Types.FETCH_CLIENT_FAILURE]: getClientFailure,
  [Types.FETCH_CLIENT_BY_DOC]: getClientByDoc,
  [Types.FETCH_CLIENT_BY_DOC_SUCCESS]: getClientByDocSuccess,
  [Types.FETCH_CLIENT_BY_DOC_FAILURE]: getClientByDocFailure,
  [Types.POST_CLIENT]: insertClient,
  [Types.POST_CLIENT_SUCCESS]: insertClientSuccess,
  [Types.POST_CLIENT_FAILURE]: insertClientFailure,
  [Types.DELETE_CLIENT]: removeClient,
  [Types.DELETE_CLIENT_SUCCESS]: removeClientSuccess,
  [Types.DELETE_CLIENT_FAILURE]: removeClientFailure,

})