import { call, put } from 'redux-saga/effects';
import api from 'services/api';
import { Creators } from 'store/ducks/orders';

export function* getOrders() {
  try {
    const res = yield call(api.get, `/v1/order/order`);
    const { status, statusText, data } = res;
    
    if((status === 200 || status===201)){
      const orders = data.body;
      yield put(Creators.fetchOrdersSuccess(orders));
    } else {
      yield put(
        Creators.fetchOrdersFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.fetchOrdersFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

export function* getOrder({id}) {
  try {
    const res = yield call(api.get, `/v1/order/order?id=${id}`);
    const { status, statusText, data } = res;
    //console.log(res);
    
    if((status === 200 || status===201)){
      const order = data.body;
      yield put(Creators.fetchOrderSuccess(order));
    } else {
      yield put(
        Creators.fetchOrderFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.fetchOrderFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

export function* getByOrderNumber({orderNumber}) {
  try {
    const res = yield call(api.get, `/v1/order/order?orderNumber=${orderNumber}`);
    const { status, statusText, data } = res;
    //console.log(res);
    
    if((status === 200 || status===201)){
      const order = data.body;
      yield put(Creators.fetchByOrderNumberSuccess(order));
    } else {
      yield put(
        Creators.fetchByOrderNumberFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.fetchByOrderNumberFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

export function* getByProvider({provider, dateFrom, dateTo}) {
  try {
    const res = yield call(api.get, `/v1/order/order?provider=${provider}&dateFrom=${dateFrom}&dateTo=${dateTo}`);
    const { status, statusText, data } = res;
    
    if((status === 200 || status===201)){
      const orders = data.body;
      // console.log(orders);
      // console.log(Object.keys(orders[0]));
      yield put(Creators.fetchOrdersSuccess(orders));
    } else {
      yield put(
        Creators.fetchOrdersFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.fetchOrdersFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

export function* getAllYear() {
  try {
    const res = yield call(api.get, `/v1/order/reports`);
    const { status, statusText, data } = res;
    
    if((status === 200 || status===201)){
      const reports = data.body;
      yield put(Creators.fetchAllYearSuccess(reports));
    } else {
      yield put(
        Creators.fetchAllYearFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.fetchAllYearFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

export function* getByYear({year}) {
  try {
    const res = yield call(api.get, `/v1/order/reports?year=${year}`);
    const { status, statusText, data } = res;
    
    if((status === 200 || status===201)){
      const reports = data.body;
      yield put(Creators.fetchByYearSuccess(reports));
    } else {
      yield put(
        Creators.fetchByYearFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.fetchByYearFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

export function* getByDayByDay() {
  try {
    const res = yield call(api.get, `/v1/order/reports?dayToday=true`);
    const { status, statusText, data } = res;
    
    if((status === 200 || status===201)){
      const reports = data.body;
      yield put(Creators.fetchByDayByDaySuccess(reports));
    } else {
      yield put(
        Creators.fetchByDayByDayFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.fetchByDayByDayFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

export function* updateOrder({ order }) {
  try {
    const res = yield call(api.put, `/v1/order/order`, order);
    const { status, statusText, data } = res;
    
    if((status === 200 || status===201)){
      const _data = data.body;
      yield put(Creators.putOrderSuccess(order));
    } else {
      yield put(
        Creators.putOrderFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.putOrderFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

export function* getFtpOrders({provider}) {
  try {
    const res = yield call(api.get, `/v1/ftp/downloads?provider=${provider}`);
    const { status, statusText, data } = res;
    
    if((status === 200 || status===201)){
      const ftp = data.body;
      console.log('getFtpOrders::ftp', ftp)
      yield put(Creators.downloadFtpOrdersSuccess(ftp));
    } else {
      yield put(
        Creators.downloadFtpOrdersFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.downloadFtpOrdersFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}