import { takeEvery } from 'redux-saga/effects';
import { Types } from 'store/ducks/tracking';
import { getTracking } from './tracking';

const trackingSagas = [
	takeEvery(Types.FETCH_TRACKING, getTracking),
  takeEvery(Types.FETCH_TRACKING_SUCCESS, () => {
  }),
  takeEvery(Types.FETCH_TRACKING_FAILURE, () => {
  })
]

export default trackingSagas;