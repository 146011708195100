import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Button, Grid, IconButton, Paper, Toolbar, Typography, TextField } from "@material-ui/core";

import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from "@material-ui/data-grid";
import WhatsApp from '@material-ui/icons/WhatsApp';
import Box from '@material-ui/core/Box'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select, { SelectChangeEvent } from '@material-ui/core/Select';

import moment from 'moment-timezone';
import { CSVLink } from "react-csv";

import { customersColors } from "services/constants";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import DialogQuest from 'pages/components/DialogQuest';
import ColoredLinearProgress from 'pages/components/LineProgress';
import Home from 'pages/home';

// Actions
import { Creators } from 'store/ducks/orders';
import { Creators as CreatorsClients } from 'store/ducks/clients';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  conteiner:{
    fontSize: 14, 
    textAlign: 'justify'
  },
  titulo: {
    margin: 0, 
    padding: 0, 
    fontSize: 14, 
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor:'#ddd',
    color: '#000'

  },

  dadosh1: {
    fontSize: 16, 
    textAlign: 'left', 
    fontWeight: 400,
    color: '#000000DE'
  },

  dadosTitulo: {
    fontSize: 16, 
    textAlign: 'left', 
    fontWeight: 400,
    color: '#0000008A'
  },

  dadosh2:{
    fontSize: 16, 
    textAlign: 'justify', 
    fontWeight: 400,
    color: '#000000DE'
  
  },

  svg:{
    color: '#000',
    width: 50,
    height: 20
  },
  vCenter:{
    display: 'flex',
    alignItems: 'center'
  },
  btnExportCSV:{
    border: '1px solid gray',
    borderRadius: 5,
    padding: 5,
    color: 'gray',
    paddingLeft: 10,
    paddingRight: 10,
    textDecoration: 'none',
    marginLeft: 20,
    marginRight: 10
  }
  
}));

const Index = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.signin);
  const { pending, orders, order, ftp } = useSelector(state => state.orders);
  const { pending: pendingClients, clients } = useSelector(state => state.clients);

  const [filterType, setFilterType] = React.useState(0);

  const [dialogOpened, setDialogOpened] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  
  const [filterText, setFilterText] = useState('');

  const [selectedDateFrom, setSelectedDateFrom] = useState(moment());
  const [strSelectedDateFrom, setStrSelectedDateFrom] = useState(moment().format("DD/MM/YYYY"));
  const [selectedDateTo, setSelectedDateTo] = useState(moment());
  const [strSelectedDateTo, setStrSelectedDateTo] = useState(moment().format("DD/MM/YYYY"));

  const csvHeaders = [
    { label: "ID", key: "id" },
    { label: "Provider", key: "provider" },
    { label: "Status", key: "status" },
    { label: "Motivo", key: "motive" },
    { label: "Código Resposta", key: "code_response" },
    { label: "Mensagem Resposta", key: "message_response" },
    { label: "Data de envio", key: "shipped_date" },
    { label: "Nº Ordem", key: "order_number" },
    { label: "Cod. Rastreio", key: "tracking_code" },
    { label: "Itens", key: "items_quantity" },
    { label: "NF Série", key: "invoice_series" },
    { label: "NF Número", key: "invoice_number" },
    { label: "NF Chave", key: "invoice_key" },
    { label: "Cubagem", key: "weight" },
    { label: "Tipo encomenda", key: "shipment_order_type" },
    { label: "Ref", key: "shipping_reference" },
    { label: "Nome", key: "first_name" },
    { label: "Sobrenome", key: "last_name" },
    { label: "Telefone", key: "phone" },
    { label: "Celular", key: "cellphone" },
    { label: "E-mail", key: "email" },
    { label: "Endereço", key: "shipping_address" },
    { label: "Número", key: "shipping_number" },
    { label: "Complemento", key: "shipping_additional" },
    { label: "Bairro", key: "shipping_quarter" },
    { label: "Cidade", key: "shipping_city" },
    { label: "Estado", key: "shipping_state" },
    { label: "País", key: "shipping_country" },
    { label: "CEP", key: "shipping_zip_code" },
    { label: "Enviado por", key: "origin_name" },
    { label: "CNPJ", key: "sender_cnpj" },
    { label: "Telefone", key: "origin_customer_phone" },
    { label: "Endereço", key: "origin_street" },
    { label: "Número", key: "origin_number" },
    { label: "Complemento", key: "origin_additional" },
    { label: "Ref", key: "origin_reference" },
    { label: "Bairro", key: "origin_quarter" },
    { label: "Cidade", key: "origin_city" },
    { label: "Estado", key: "origin_state_code" },
    { label: "CEP", key: "origin_zip_code" },
    { label: "Premium?", key: "is_premium" },
    { label: "Expedidor CNPJ", key: "cnpj_expeditor" },
    { label: "Criado em", key: "created_at" },
  ];
 
  useEffect(() => {
    dispatch(CreatorsClients.fetchClients())
  },[]);

  useEffect(() => {
    setFilterText('');
  },[filterType]);

  useEffect(() => {
    if(ftp) {
      handlerFilter();
    }
  },[ftp]);

  const handleLoadData = (params) => {
    //console.log(params);
    //const ITEM = orders.find(c => c.params === c.id);
    //setSelectedItem(ITEM);
    //if(ITEM) {
     // dispatch(Creators.fetchCompany(ITEM?.id));
    //}
    //setDialogOpened(true);
  }

  const onDialogClose = () => {
    setSelectedItem(null);
    setDialogOpened(false);
  }
  
  const onConfirmPress = () =>  {
    onDialogClose();
  }

  const handlerFilter = () => {
    if(filterType === 0){
      dispatch(Creators.fetchByOrderProvider(filterText, strSelectedDateFrom, strSelectedDateTo));
    } else {
      dispatch(Creators.fetchByOrderNumber(filterText));
    }
  }
  
  const handlerImportFTP = () => {
    //...
    if(filterType === 0) {
      dispatch(Creators.downloadFtpOrders(filterText));
    }
  }

  const onDateChangeSelectedDateFrom = (date, value) => {
    setSelectedDateFrom(date);
    setStrSelectedDateFrom(value);
  };

  const onDateChangeSelectedDateTo = (date, value) => {
    setSelectedDateTo(date);
    setStrSelectedDateTo(value);
  };

  const dateFormatter = str => {
    return str;
  };

  const Line = (props) => {
    const classes = useStyles();
    const {label, text, center, isWhatsapp} = props;
    return (
      <div style={{padding:2, width:'100%'}}>
        {label
          ? <Typography variant="body2" color="textSecondary">
              {label}
            </Typography>
          : null
        }
        {center
        ? <div className={classes.center}>
            <Typography color="primary" component="h1" variant="h5">
              {text} {isWhatsapp ? <WhatsApp /> : null}
            </Typography>
          </div>
        : <Typography>
            {text} {isWhatsapp ? <WhatsApp /> : null}
          </Typography>
        }
      </div>
    )
  }

  const getData = () => {
    const DATA = [].concat(orders).map((o, idx) => {
      return {
        id: o?.id,
        shippedDate: o?.shipped_date ? moment(o?.shipped_date).format('DD/MM/YYYY HH:mm:ss') : '',
        orderNumber: o?.order_number,
        trackingCode: o?.tracking_code,
        invoiceSeries: o?.invoice_series,
        invoiceNumber: o?.invoice_number,
        invoiceKey: o?.invoice_key,
        shipment_order_type: o?.shipment_order_type,
        status: o?.status,
        motive: o?.motive,
        codeResponse: o?.code_response,
        messageResponse: o?.message_response,
        shippingName: `${o?.first_name} ${o?.last_name}`,
        shippingCellphone: o?.cellphone,
        shippingEmail: o?.email,
        shippingAddress: `${o?.shipping_address}, ${o?.shipping_number}`,
        originName: o?.origin_name,
        originReference: o?.origin_reference,
        originPhone: o?.origin_customer_phone,
        originAddress: `${o?.origin_street}, ${o?.origin_number}`,
        provider: o?.provider,
        cnpjExpeditor: o?.cnpj_expeditor,
        itemsQuantity: Number(o?.items_quantity).toLocaleString(undefined, { maximumFractionDigits: 3, minimumFractionDigits: 0 }),
        orderDate: moment(o?.created_at).format("DD/MM/YYYY HH:mm:ss"),
        updateOrderDate: moment(o?.updated_at).format("DD/MM/YYYY HH:mm:ss")
      }
    })
    return DATA
  }
  
  const getProviderColor = (provider) => {
    const color = customersColors.find(c => c.provider === provider)?.color;

    return color ? color : '#696969';
  }

  const columns = [
    { field: "id", headerName: " ", hide:true },
    { field: "orderDate", headerName: "Dt. Ordem", width: 160 },
    { field: "updateOrderDate", headerName: "Dt. At. Ordem", width: 160 },
    { field: "shippedDate", headerName: "Data", width: 160 },
    { field: "provider", headerName: "Provider", width: 150, 
      renderCell: (params) => (
        <span>
          <span style={{backgroundColor: getProviderColor(params?.value), width:10, height:10, borderRadius:5, display:'inline-flex'}}></span>
          
          <span style={{fontWeight: 'bold', color: getProviderColor(params?.value), marginLeft:5}}>{params?.value}</span>
        </span>
      )
    },
    { field: "orderNumber", headerName: "Nº Ordem", width: 200, 
      renderCell: (params) => (
        <span style={{fontWeight: 'bold', color: 'blue', marginLeft:5}}>{params?.value}</span>
      )
    },
    { field: "trackingCode", headerName: "Cod. Rastreio", width: 250, 
      renderCell: (params) => (
        <span style={{fontWeight: 'bold', color: 'green', marginLeft:5}}>{params?.value}</span>
      )
    },
    { field: "invoiceKey", headerName: "NF Chave", width: 400 },
    { field: "invoiceSeries", headerName: "NF Serie", width: 100 },
    { field: "invoiceNumber", headerName: "NF Número", width: 150 },
    { field: "itemsQuantity", headerName: "Itens", width: 120 },
    { field: "shipment_order_type", headerName: "Tipo", width: 150 },
    { field: "motive", headerName: "Status", width: 150 },
    { field: "codeResponse", headerName: "Código", width: 150 },
    { field: "messageResponse", headerName: "Mensagem", width: 250 },
    { field: "shippingName", headerName: "Nome", width: 300 },
    { field: "shippingCellphone", headerName: "Celular", width: 150 },
    { field: "shippingEmail", headerName: "E-mail", width: 300 },
    { field: "shippingAddress", headerName: "Endereço", width: 400 },
    { field: "originName", headerName: "Empresa", width: 300 },
    { field: "originReference", headerName: "Origem", width: 300 },
    { field: "originPhone", headerName: "Origen Tel", width: 150 },
    { field: "originAddress", headerName: "Endereço", width: 400 },
    { field: "cnpjExpeditor", headerName: "CNPJ Expedidor", width: 200 }
  ];

  const handleFilterType = (event) => {
    const value = event.target.value;
    console.log(value);
    setFilterType(value);
  };

  const getFormatedExportData = () =>  {
    const DATA = [].concat(orders).map((o, idx) => {
      const newItem = {
        shipped_date: o?.shipped_date ? moment(o?.shipped_date).format('DD/MM/YYYY HH:mm:ss') : '',
        items_quantity: Number(o?.items_quantity).toLocaleString(undefined, { maximumFractionDigits: 3, minimumFractionDigits: 0 }) || 0,
        created_at: moment(o?.created_at).format("DD/MM/YYYY HH:mm:ss") || ''
      }

      return {...o, ...newItem}
    })
    return DATA
  }

  return (
    <Home title={'Ordens de Serviço'}>
      {pending ? <ColoredLinearProgress /> : null }
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel>Buscar por:</InputLabel>
                <Select
                  value={filterType}
                  onChange={handleFilterType}
                >
                  <MenuItem value={0}>Provider</MenuItem>
                  <MenuItem value={1}>Nº Ordem</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item>
            {filterType === 0 &&              
              <Box sx={{ minWidth: 250 }}>
                {pendingClients 
                  ? <CircularProgress size="20px" /> 
                  : <FormControl fullWidth>
                      <InputLabel>Escolha um provider</InputLabel>
                      <Select
                        value={filterText}
                        onChange={e => setFilterText(e.target.value)}
                      >
                        {
                          [...clients].map((item, idx) => (
                            <MenuItem key={`${idx}`} value={item.provider}>{item.name}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                }
              </Box>
            }
            {filterType === 1 &&
              <TextField disabled={pending}
                value={filterText}
                onChange={e => setFilterText(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={filterType === 0 ? 'Empresa/Provider' : 'Número da Ordem'}
                autoFocus
              />
            }
          </Grid>

          {filterType === 0 
            ? <>
                <Grid item >
                  <div style={{padding:20}}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                      <KeyboardDatePicker disabled={pending}
                        disableFuture
                        autoOk={true}
                        label="De:"
                        showTodayButton={true}
                        value={selectedDateFrom}
                        format="DD/MM/YYYY"
                        inputValue={strSelectedDateFrom}
                        onChange={onDateChangeSelectedDateFrom}
                        rifmFormatter={dateFormatter}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Grid>
                <Grid item>
                  <div style={{padding:20}}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                      <KeyboardDatePicker disabled={pending}
                        disableFuture
                        autoOk={true}
                        label="a:"
                        showTodayButton={true}
                        value={selectedDateTo}
                        format="DD/MM/YYYY"
                        inputValue={strSelectedDateTo}
                        onChange={onDateChangeSelectedDateTo}
                        rifmFormatter={dateFormatter}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Grid>
              </>
            : null
          }
          <Grid item className={classes.vCenter}>
            <Button disabled={pending} type="button" variant="contained" color="primary"
              onClick={()=>handlerFilter()}>Filtrar</Button>
            
            <CSVLink data={getFormatedExportData()} headers={csvHeaders} className={classes.btnExportCSV}>
              Exportar CSV
            </CSVLink>
          </Grid>
        </Grid>

        <div style={{display:'flex', justifyContent:'flex-end'}}>
          

          {/* {(filterType === 0 && filterText !=== "") &&
            <Button disabled={pending} type="button" variant="contained" color="default"
              onClick={()=>handlerImportFTP()}>Importar FTP</Button>
          } */}
        </div>
      </Paper>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <DataGrid rows={getData()} columns={columns} density="compact"
              style={{minHeight: '70vh'}}
              autoPageSize={true} 
              disableColumnMenu={false} 
              hideFooterSelectedRowCount={false} />
          </Paper>
        </Grid>
      </Grid>
      
    </Home>
  );
}

export default Index