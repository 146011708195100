import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  fetchTracking: ['orderId'],
  fetchTrackingSuccess: ['data'],
  fetchTrackingFailure: ['error'],
  cleanTracking: []
});

/**
 * Handlers
 */
const STATE = {
  pending: false,
  data: null,
  error: null
}
const INITIAL_STATE = STATE;

// getTracking
const getTracking = state => {
  return {...state, pending:true, data:null, error:null};
};

const getTrackingSuccess = (state, { data }) => {
  return {...state, data, pending:false, error:false};
};

const getTrackingFailure = (state, { error }) => {
  return {...state, pending:false, error };
};

const cleanTracking = state => INITIAL_STATE;

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.FETCH_TRACKING]: getTracking,
  [Types.FETCH_TRACKING_SUCCESS]: getTrackingSuccess,
  [Types.FETCH_TRACKING_FAILURE]: getTrackingFailure,
  [Types.CLEAN_TRACKING]: cleanTracking
});