import { Box, Button, Container, CssBaseline, Grid, Link, TextField, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Copyright from 'pages/components/Copyright';
import Logomarca from 'pages/components/logo';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
// Actions
import { Creators } from 'store/ducks/validate';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const Index = (props, context) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { pending, checked, updated, error, data } = useSelector(state => state.validate);

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [code, setCode] = useState('');
  const [isEmail, setIsEmail] = useState(false);
  const [isCellphone, setIsCellphone] = useState(false);

  useEffect(() => {
    setIsEmail(false)
    setIsCellphone(false)
    // if(history) {
    //   const {email, cellphone} = history.location.state
    //   if(email) {
    //     setIsEmail(true)
    //   } else if(cellphone) {
    //     setIsCellphone(true)
    //   } 
    // }
  },[]);

  useEffect(() => {
    if(updated===true){
      navigate('/')
    }
  },[updated]);

  useEffect(() => {
    if(checked===true){
      dispatch(Creators.putPassword(userName, password));
    }
  },[checked]);

  const handleSubmit = () => {
    if(isEmail) {
      dispatch(Creators.checkEmailCode(userName, code));
    } else if(isCellphone) {
      dispatch(Creators.checkCellphoneCode(userName, code));
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {!pending && checked && !updated
          ? <div style={{padding:20, backgroundColor:'#fffbe6', border:'1px dotted #252525', borderRadius:20}}>
              <Typography color="secondary" component="h1">
                Verifique os dados e tente novamente!
              </Typography>
            </div>
          : null
        }

        <Logomarca />
        <Typography component="h1" variant="h5">
          Nova Senha
        </Typography>

        <div className={classes.form} noValidate>
          <TextField disabled={pending}
            value={userName}
            onChange={e => setUserName(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="userName"
            label={isEmail?'E-Mail':isCellphone?'Celular':'E-Mail'}
            name="userName"
            autoFocus
          />
          <TextField disabled={pending}
            value={password}
            onChange={e => setPassword(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
          />
          <TextField disabled={pending}
            value={repassword}
            onChange={e => setRepassword(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="repassword"
            label="Repita a Senha"
            type="password"
            id="repassword"
          />
          <TextField disabled={pending}
            value={code}
            onChange={e => setCode(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="code"
            label="Código de Cofirmação"
            name="code"
          />
          
          <Button disabled={pending}
            fullWidth
            type="button"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            {pending?'conectando...':'CONFIRMAR'}
          </Button>
        </div>
      </div>
      <Grid container>
        <Grid item xs>
          <Link href="#" variant="body2" onClick={()=>navigate('/')}>
            Fazer Login
          </Link>
        </Grid>
        <Grid item>
          <Link href="#" variant="body2" onClick={()=>navigate('signup')}>
            {"Não tem conta? Inscrever-se"}
          </Link>
        </Grid>
      </Grid>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default Index
