import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Paper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import DialogInfo from "pages/components/DialogInfo";
import DialogQuest from "pages/components/DialogQuest";
import ColoredLinearProgress from "pages/components/LineProgress";
import Home from "pages/home";
import copy from 'copy-to-clipboard';

// Actions
import { Creators } from "store/ducks/clients";

//style ustilizado
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },

  user: {
    display: "flex",
    flexDirection: "column",
    width: 300,
    height: "auto",
  },
}));

const Index = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pending, clients, modified } = useSelector((state) => state.clients);
  const [questionOpened, setQuestionOpened] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editAdd, setAddOpened] = useState(false);
  const [infoOpened, setInfoOpened] = useState(false);
  const [name, setName] = useState("");
  const [provider, setProvider] = useState("");
  const [doc, setDoc] = useState("");

  const onQuestionClose = () => setQuestionOpened(false);

  useEffect(() => {
    dispatch(Creators.fetchClients());
  }, []);

  useEffect(() => {
    if(modified === true){
      dispatch(Creators.fetchClients());
    }
  }, [modified]);

  //criar novos clients -> ação
  const onAddPress = (e) => {
    e.preventDefault();

    if(name.trim().length < 3) return
    if(doc.trim().length < 11 ) return

    const _provider = String(provider).replace(/ /g, "-").toLowerCase();
    dispatch(Creators.postClient({name, doc, provider:_provider}));
    setAddOpened(false);
  };

  //criar novos clients -> dialogo
  const onQuestionAddClose = () => {
    setAddOpened(false);
    setName("");
    setDoc("");
  };

  //criar novos clients -> dialogo
  const handleAdd = (params) => {
    setAddOpened(true);
  };

  //copy datato clipboard
  const handleCopy = (params) => {
    const { row } = params;
    const { id, name, cpf_cnpj, token_prod, token_staging } = row;
    const text = `id: ${id}\nnome: ${name}\ndoc: ${cpf_cnpj}\nToken Prod: ${token_prod}\nToken Staging: ${token_staging}`;
    copy(text);
  };

  //remover clients -> dialogo
  const handleDelete = (params) => {
    const ITEM = clients.find((v) => v.id === params.row.id);
    setSelectedItem(ITEM);
    //abre o dialogo que confirma exclusão
    setQuestionOpened(true);
  };
  //remover clients -> ação
  const onDeletePress = (params) => {
    dispatch(Creators.deleteClient(selectedItem?.id));
    setQuestionOpened(false);
  };

  const columns = [
    { field: "id", headerName: "Id" },
    { field: "name", headerName: "Nome", width: 200 },
    { field: "provider", headerName: "Provider", width: 200 },
    { field: "cpf_cnpj", headerName: "CPF/CNPJ", width: 200 },
    { field: "token_prod", headerName: "Token Prod", width: 200 },
    { field: "token_staging", headerName: "Token Staging", width: 200 },
    {
      field: "action",
      headerName: "Ações",
      width: 200,
      renderCell: (params) => (
        <>
          {clients ? (
            <>
              <Button
                size="small"
                type="button"
                variant="contained"
                color="secondary"
                style={{ marginLeft: 8 }}
                onClick={() => handleDelete(params)}
              >
                Remover
              </Button>

              <Button
                size="small"
                type="button"
                variant="contained"
                color="default"
                style={{ marginLeft: 8 }}
                onClick={() => handleCopy(params)}
              >
                Copiar
              </Button>
            </>
          ) : (
            <>
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <Home title={"Clientes e Tokens"}>
      {pending ? <ColoredLinearProgress /> : null}
      <br />
      <Button
        size="small"
        type="button"
        variant="contained"
        color="default"
        style={{ float: "right" }}
        onClick={() => handleAdd()}
      >
        Adicionar
      </Button>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <DataGrid
              rows={clients}
              columns={columns}
              density="comfortable"
              autoHeight={true}
              autoPageSize={true}
              disableColumnMenu={true}
              hideFooterSelectedRowCount={true}
            />
          </Paper>
        </Grid>
      </Grid>

      {editAdd ? (
        <DialogQuest
          title={"Novo Cliente"}
          fullWidth
          onConfirm={onAddPress}
          confirmLabel={"SALVAR"}
          opened={editAdd}
          onClose={onQuestionAddClose}
        >
          {pending ? "Aguarde..." : ""}
          <div className={classes.add}>
            <TextField
              disabled={pending}
              value={name}
              onChange={(e) => setName(e.target.value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Nome"
              name="name"
              autoFocus
            />

            <TextField
              disabled={pending}
              value={provider}
              onChange={(e) => setProvider(e.target.value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="provider"
              label="Provider"
              name="provider"
              autoFocus
            />

            <TextField
              disabled={pending}
              value={doc}
              onChange={(e) => setDoc(e.target.value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="doc"
              label="CPF/CNPJ"
              name="doc"
              autoFocus
              type="doc"
            />

            <br />
          </div>
        </DialogQuest>
      ) : null}
      {infoOpened ? (
        <DialogInfo
          title={"Mensagem"}
          message={"Necessário preencher todos os campos!"}
          opened={infoOpened}
          onClose={() => setInfoOpened(false)}
        />
      ) : null}

      {questionOpened ? (
        <DialogQuest
          title={"Tem certeza?"}
          message={"Essa ação não poderá ser desfeita!"}
          onConfirm={onDeletePress}
          confirmLabel={"REMOVER CLIENTE"}
          opened={questionOpened}
          onClose={onQuestionClose}
        />
      ) : null}
    </Home>
  );
};

export default Index;
