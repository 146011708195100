import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FilledInput, Grid, Paper, TextField } from "@material-ui/core";
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ColoredLinearProgress from 'pages/components/LineProgress';
import Home from 'pages/home';

// Actions
import { Creators } from 'store/ducks/bulk';
import { Creators as CreatorsClients } from 'store/ducks/clients';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  vCenter:{
    display: 'flex',
    alignItems: 'center'
  },
  conteiner:{
    direction: 'row', 
    justifyContent: 'center', 
    alignItems: 'center'
  },
  fileInput: {
    backgroundColor: 'white',
    border: '1px solid #ced4da', // borda cinza suave
    borderRadius: theme.shape.borderRadius, // borda arredondada (padrão do tema)
    padding: theme.spacing(1.5), // espaçamento interno
    '&:hover': {
      backgroundColor: '#f8f9fa', // cor de fundo no hover
    },
    '&.Mui-focused': {
      borderColor: theme.palette.primary.main, // cor da borda quando focado
      boxShadow: `0 0 0 2px ${theme.palette.primary.light}`, // sombra para destacar
    },
    '& input': {
      '&::placeholder': {
        color: '#adb5bd', // cor do placeholder
        opacity: 1,
      },
    },
    '&.Mui-error': {
      borderColor: theme.palette.error.main, // cor da borda para estado de erro
    },
  },
}));

const STATUS_LIST = {
  REQUEST_RECEIVED: 'Pedido Recebido',
  PICKUP_ACCEPTED: 'Coleta aceita',
  PICKUP_STARTED: 'Coleta iniciada',
  PICKUP_SUCCESSFUL: 'Sucesso na coleta',
  PICKUP_FAILURE: 'Falha na coleta',
  PICKUP_IN_PROGRESS: 'Coleta em andamento',
  PICKUP_CANCELED: 'Coleta cancelada',
  PICKUP_DELETED: 'Coleta apagada',

  DELIVERY_ACCEPTED: 'Pedido de entrega aceito',
  DELIVERY_STARTED: 'Pedido de entrega iniciado',
  DELIVERY_SUCCESSFUL: 'Sucesso na entrega',
  DELIVERY_FAILURE: 'Falha na entrega',
  DELIVERY_IN_PROGRESS: 'Entrega em andamento',
  DELIVERY_CANCELED: 'Entrega cancelada',
  DELIVERY_DELETED: 'Entrega apagada',
}

const Index = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pending, error, data } = useSelector(state => state.bulk);
  const { pending: pendingClients, clients } = useSelector(state => state.clients);
  const [company, setCompany] = useState('');
  const [newFile, setNewFile] = useState("");
  const [fileBase64, setFileBase64] = useState("");
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [code_response, setCodeResponse] = useState("");
  
  useEffect(() => {
    dispatch(CreatorsClients.fetchClients())
  },[]);

  useEffect(() => {
    setCompany("");
    setFileBase64("");
    setNewFile("");
    setStatus("");
    setMessage("");
    setCodeResponse("");
  },[data]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(Creators.clearBulk());
    }, 10000); // 10000ms = 10 segundos
    return () => clearTimeout(timer); // Limpa o timer se o componente é desmontado
  },[data, error]);

  const handlerSendStatus = () => {
    // dispara o send status
    dispatch(Creators.fetchBulk(company,  fileBase64, status, message, code_response));
  }
  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const base64String = e.target.result.split(',')[1];
            console.log('base64String');
            console.log(base64String);
            setFileBase64(base64String);
        };
        reader.readAsDataURL(file);
    }
};
  return (
    <Home title={'Enviar novo status'}>
      <Grid className={classes.conteiner} container spacing={3} columns={{ xs: 6, sm: 8, md: 12 }}>
        <Paper className={classes.paper}>
          {pending ? <ColoredLinearProgress /> : null }
          {!pending && data ? 
            <div style={{padding:20}}>
              <Alert severity="success">{data?.message}</Alert>
            </div>
            : null 
          }
          {!pending && error ? 
            <div style={{padding:20}}>
              <Alert severity="error">{error?.message}</Alert>
            </div>
            : null 
          }
          <Grid item xs={12}>
            <Box sx={{ minWidth: 120 }}>
              {pendingClients 
                ? <CircularProgress size="20px" /> 
                : <FormControl fullWidth autoFocus>
                    <InputLabel>Escolha uma empresa</InputLabel>
                    <Select
                      value={company}
                      onChange={e => setCompany(e.target.value)}
                    >
                      {
                        [...clients].map((item, idx) => (
                          <MenuItem key={`${idx}`} value={item.provider}>{item.name}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
              }
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel>Selecione o Status</InputLabel>
                <Select
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                >
                  {
                    Object.entries(STATUS_LIST).map(([key, value]) => (
                      <MenuItem key={`status_list_${key}`} value={key}>{value}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField id="message" name="message" label="Mensagem" disabled={pending} required
              value={message} onChange={(e) => setMessage(e.target.value)}
              variant="outlined" margin="normal" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField id="code_response" name="code_response" label="Código" disabled={pending} required
              value={code_response} onChange={(e) => setCodeResponse(e.target.value)}
              variant="outlined" margin="normal" fullWidth />
          </Grid>
          <Grid item xs={12} >
            <div style={{padding:20}}>
              <FilledInput className={classes.fileInput} type="file" inputProps={{ accept: '.csv' }} value={newFile} onChange={handleFileChange}/>
            </div>
            <div style={{padding:20}}>
              <Alert severity="warning">Enviar um arquivo CSV sem título <br />
              somente os número da ordem de serviço na <br />
              primeira coluna e um em cada linha!</Alert>
            </div>
          </Grid>
          <Grid item className={classes.vCenter}>
            {/* <Button disabled={pending} type="button" variant="contained" color="primary" */}
            <Button type="button" variant="contained" color="primary" disabled={!company || !fileBase64 || !status || !message|| !code_response || pending}
              onClick={()=>handlerSendStatus()}>Enviar Novo Status</Button>
          </Grid>
        
        </Paper>
      </Grid>
    </Home>
  );
}

export default Index