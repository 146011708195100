import { takeEvery } from 'redux-saga/effects';
import { Types } from 'store/ducks/clients';
import { getClients, getClient, getClientByDoc, insertClient, removeClient } from './clients';

const clientsSagas = [
	takeEvery(Types.FETCH_CLIENTS, getClients),
	takeEvery(Types.FETCH_CLIENT, getClient),
	takeEvery(Types.FETCH_CLIENT, getClientByDoc),
	takeEvery(Types.POST_CLIENT, insertClient),
	takeEvery(Types.POST_CLIENT_SUCCESS, () => {
  }),
	takeEvery(Types.DELETE_CLIENT, removeClient),
	takeEvery(Types.DELETE_CLIENT_SUCCESS, () => {
  })
]

export default clientsSagas;