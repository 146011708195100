import { call, put } from 'redux-saga/effects';
import api from 'services/api';
import { Creators } from 'store/ducks/validate';

export function* sendEmail({ email }) {
  try {
    const res = yield call(api.post, '/v1/common/validemail?create=true', {email});

    const { status, statusText, data } = res;

    if(status === 200 || status===201){
      yield put(Creators.sendEmailSuccess(data.body));
    } else {
      yield put(
        Creators.sendEmailFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.sendEmailFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

export function* checkEmailCode({ email, code }) {
  try {
    const res = yield call(api.post, '/v1/common/validemail?verify=true', {email, code});

    const { status, statusText, data } = res;

    if(status === 200 || status===201){
      yield put(Creators.checkEmailCodeSuccess(data.body));
    } else {
      yield put(
        Creators.checkEmailCodeFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.checkEmailCodeFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

export function* sendCellphone({ cellphone }) {
  try {
    const res = yield call(api.post, '/v1/common/validcellphone?create=true', {cellphone});

    const { status, statusText, data } = res;

    if(status === 200 || status===201){
      yield put(Creators.sendCellphoneSuccess(data.body));
    } else {
      yield put(
        Creators.sendCellphoneFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.sendCellphoneFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

export function* checkCellphoneCode({ cellphone, code }) {
  try {
    const res = yield call(api.post, '/v1/common/validcellphone?verify=true', {cellphone, code});

    const { status, statusText, data } = res;

    if(status === 200 || status===201){
      yield put(Creators.checkCellphoneCodeSuccess(data.body));
    } else {
      yield put(
        Creators.checkCellphoneCodeFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.checkCellphoneCodeFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

export function* updatePassword({ userName, password }) {
  try {
    const res = yield call(api.put, '/v1/company/password', {userName, password});

    const { status, statusText, data } = res;

    if(status === 200 || status===201){
      yield put(Creators.putPasswordSuccess(data.body));
    } else {
      yield put(
        Creators.putPasswordFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.putPasswordFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}
