import { createActions, createReducer } from "reduxsauce";
/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  fetchBulk: ['company', 'fileBase64', 'status', 'message', 'code_response'],
  fetchBulkSuccess: ['data'],
  fetchBulkFailure: ['error'],
  clearBulk: []
});

/**
 * Handlers
 */
const STATE = {
  pending: false,
  data: null,
  error: null
}
const INITIAL_STATE = STATE;

// bulk
const sendBulk = state => {
  return {...state, pending: true, error: null};
};

const sendBulkSuccess = (state, { data }) => {
  return {...state, data, pending: false, error: false};
};

const sendBulkFailure = (state, { error }) => {
  return {...state, pending: false, error, data: null };
};

const clearBulk = state => {
  return {...state, pending: false, data:null, error: null};
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.FETCH_BULK]: sendBulk,
  [Types.FETCH_BULK_SUCCESS]: sendBulkSuccess,
  [Types.FETCH_BULK_FAILURE]: sendBulkFailure,
  [Types.CLEAR_BULK]: clearBulk,
});