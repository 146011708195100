import { Box, Button, Container, CssBaseline, Grid, Link, TextField, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Copyright from 'pages/components/Copyright';
import Logomarca from 'pages/components/logo';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getUser } from 'services/auth';
// Actions
import { Creators } from 'store/ducks/signin';
import { Creators as CreatorsValidate } from 'store/ducks/validate';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const Index = (props, context) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { pending, error, user } = useSelector(state => state.signin);
  const { updated } = useSelector(state => state.validate);

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    proccessLogin();
  });

  useEffect(() => {
    if(!error && user && user?.hash){
      proccessLogin();
    }
  },[user]);

  useEffect(() => {
    if(updated===true){
      setTimeout(() => {
        dispatch(CreatorsValidate.resetValidateState());
      }, 5000);
    }
  },[updated]);
  
  const proccessLogin = () => {
    const userLogged = getUser();
    if(userLogged && userLogged?.hash) {
      navigate('dashboard');
    }
  }

  const handleSubmit = () => {
    dispatch(Creators.fetchSignin(userName, password));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {updated
          ? <div style={{padding:20, backgroundColor:'#fffbe6', border:'1px dotted #252525', borderRadius:20}}>
              <Typography color="secondary" component="h1">
                Sua SENHA foi atualizada com SUCESSO!
              </Typography>
            </div>
          : null
        }

        <Logomarca />
        <Typography component="h1" variant="h5">
          Seja bem vindo ao Dashboard
        </Typography>
        <Typography component="h1" variant="h6">
          Faça seu login abaixo
        </Typography>

        <div className={classes.form} noValidate>
          <form onSubmit={handleSubmit}>
              <TextField disabled={pending}
                value={userName}
                onChange={e => setUserName(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="userName"
                label="Email/Cellphone"
                name="userName"
                autoFocus
              />
              <TextField disabled={pending}
                value={password}
                onChange={e => setPassword(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
              />
              {/* <FormControlLabel
                control={
                  <Checkbox color="primary" 
                    value={remember} 
                    checked={remember} 
                    onChange={e => setRemember(e.target.checked)} />
                }
                label="Lembrar de mim"
              /> */}
              <Button disabled={pending}
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
              >
                {pending?'conectando...':'ENTRAR'}
              </Button>
            </form>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" onClick={()=>navigate('recover')}>
                Esqueceu a senha?
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default Index
