import { takeEvery } from 'redux-saga/effects';
import { Types } from 'store/ducks/users';
import { getUser, getUsers, insertUser, removeUser, updateUser } from './users';

const usersSagas = [
	takeEvery(Types.FETCH_USER, getUser),
	takeEvery(Types.FETCH_USERS, getUsers),

	takeEvery(Types.POST_USER, insertUser),
	takeEvery(Types.POST_USER_SUCCESS, () => {
  }),
	takeEvery(Types.PUT_USER, updateUser),
	takeEvery(Types.PUT_USER_SUCCESS, () => {
  }),
	takeEvery(Types.DELETE_USER, removeUser),
	takeEvery(Types.DELETE_USER_SUCCESS, () => {
  })
]

export default usersSagas;