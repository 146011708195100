import { takeEvery } from 'redux-saga/effects';
import { Types } from 'store/ducks/bulk';
import { sendBulk } from './bulk';

const bulkSagas = [
	takeEvery(Types.FETCH_BULK, sendBulk),
  takeEvery(Types.FETCH_BULK_SUCCESS, () => {
    // Navigation.navigate('Main');
  }),
  takeEvery(Types.FETCH_BULK_FAILURE, () => {
  })
]

export default bulkSagas;