import { call, put } from 'redux-saga/effects';
import api from 'services/api';
import { Creators } from 'store/ducks/clients';

export function* getClients() {
  try {
    const res = yield call(api.get, `/v1/client/signup`);
    const { status, statusText, data } = res;
    
    if((status === 200 || status===201)){
      const clients = data.body;
      yield put(Creators.fetchClientsSuccess(clients));
    } else {
      yield put(
        Creators.fetchClientsFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.fetchClientsFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

export function* getClient({id}) {
  try {
    const res = yield call(api.get, `/v1/client/signup?id=${id}`);
    const { status, statusText, data } = res;
    
    if((status === 200 || status===201)){
      const client = data.body;
      yield put(Creators.fetchClientSuccess(client));
    } else {
      yield put(
        Creators.fetchClientFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.fetchClientFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

export function* getClientByDoc({doc}) {
  try {
    const res = yield call(api.get, `/v1/client/signup?doc=${doc}`);
    const { status, statusText, data } = res;
    
    if((status === 200 || status===201)){
      const client = data.body;
      yield put(Creators.fetchClientByDocSuccess(client));
    } else {
      yield put(
        Creators.fetchClientByDocFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.fetchClientByDocFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

export function* insertClient({ client }) {
  try {
    const res = yield call(api.post, `/v1/client/signup`, client);
    const { status, statusText, data } = res;
    
    if((status === 200 || status===201)){
      const _data = data.body;
      yield put(Creators.postClientSuccess(_data));
    } else {
      yield put(
        Creators.postClientFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.postClientFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

export function* removeClient({ id }) {
  try {
    const res = yield call(api.delete, `/v1/client/signup`, {data: {id}});
    const { status, statusText, data } = res;
    if((status === 200 || status===201)){
      const _data = data.body;
      yield put(Creators.deleteClientSuccess(_data));
    } else {
      yield put(
        Creators.deleteClientFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.deleteClientFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}
